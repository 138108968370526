var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (true)?_c('div',{staticClass:"zr-news__component",attrs:{"width":"400px"}},[(_vm.isUserLoggedInAndHasRights && !_vm.initialRedirect && _vm.newsLoaded)?_c('v-card',[_c('v-form',[_c('v-card-title',{staticClass:"pb-0"},[_c('span',{staticClass:"text-h5 Anthrazit--text"},[_vm._v(_vm._s(_vm.$t('title'))+" ")])]),_c('v-card-text',[_c('d4y-table',{attrs:{"allowAdd":_vm.isUserInAzureAdminGroupGetter,"allowDelete":_vm.isUserInAzureAdminGroupGetter,"haveMoreActions":true,"isTitleAsRef":true,"searchLabel":_vm.$t('table_search_label'),"tableHasFilterBtn":false,"tableHeaderText":_vm.tableText,"hasFooterBtn":false,"loading":_vm.zrNewsIsLoading,"serverItemsLength":_vm.getZrNews.total,"items":_vm.items,"headers":_vm.headers,"options":_vm.zrNewsSearchParams.dataOption,"searchMenuObj":_vm.searchMenuObj},on:{"action:add":_vm.onNewsAdd,"click:delete":_vm.deleteNews,"click:row":_vm.openZrNews,"update:options":_vm.selectionUpdateOptions},scopedSlots:_vm._u([{key:"bild_small",fn:function(ref){
var item = ref.item;
return [(item.bild_small)?_c('img',{staticClass:"pt-1 image-small",attrs:{"src":("data:image/png;base64," + (item.bild_small)),"height":"75","max-width":"100","alt":""}}):_vm._e()]}},{key:"created_on",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeWithLocale")(item.created_on,_vm.$i18n.locale))+" ")]}},{key:"number_of_attachments",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.news_files.filter(function (x){ return x.bucket_name == 'gsp-news-anlagen'; }).length)+" ")]}},{key:"more-actions",fn:function(ref){
var item = ref.item;
return [(_vm.isUserInAzureAdminGroupGetter)?_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"28"},on:{"click":function($event){$event.stopPropagation();return _vm.openZrNews(item.item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-square-edit-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('action_edit')))])])],1):(_vm.isUserOnlyGs)?_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"28"},on:{"click":function($event){$event.stopPropagation();return _vm.openZrNews(item.item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-eye")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('action_user_view')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.item.news_read_info[0] && item.item.news_read_info[0].is_read)?_c('v-icon',_vm._g(_vm._b({staticClass:"success--text ml-1",attrs:{"size":"28"},on:{"click":function($event){$event.stopPropagation();return _vm.changeReadStatus(item.item, false)}}},'v-icon',attrs,false),on),[_vm._v("mdi-progress-check")]):_c('v-icon',_vm._g(_vm._b({staticClass:"success--text ml-1",attrs:{"size":"28"},on:{"click":function($event){$event.stopPropagation();return _vm.changeReadStatus(item.item, true)}}},'v-icon',attrs,false),on),[_vm._v("mdi-circle-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.item.news_read_info[0] && item.item.news_read_info[0].is_read ? _vm.$t('action_user_unread') : _vm.$t('action_user_read')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.item.news_read_info[0] && item.item.news_read_info[0].is_favorite)?_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text ml-1",attrs:{"size":"28"},on:{"click":function($event){$event.stopPropagation();return _vm.markAsFavorite(item.item, false)}}},'v-icon',attrs,false),on),[_vm._v("mdi-star")]):_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"size":"28"},on:{"click":function($event){$event.stopPropagation();return _vm.markAsFavorite(item.item, true)}}},'v-icon',attrs,false),on),[_vm._v("mdi-star-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.item.news_read_info[0] && item.item.news_read_info[0].is_favorite ? _vm.$t('action_user_undo_fav') : _vm.$t('action_user_make_fav')))])])],1):_vm._e()]}}],null,false,680618344)})],1)],1)],1):(_vm.isUserLoggedInAndHasRights === false && (_vm.zrNummer === null || !_vm.isUserHasRightsForDocuments) && !_vm.initialRedirect && _vm.newsLoaded)?_c('div',[_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"2","max-width":"600"}},[_c('v-card-title',[_c('span',{staticClass:"text-h4 font-weight-light"},[_vm._v(_vm._s(_vm.$t('no_access_title')))])]),_c('v-card-text',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('no_access_text'))+" ("),_c('a',{staticClass:"primary--text",attrs:{"href":"mailto:support@meinvme.de"}},[_vm._v("support@meinvme.de")]),_vm._v(") ")])],1)],1):_c('div',[_c('v-progress-circular',{attrs:{"size":70,"width":7,"indeterminate":"","color":"primary"}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }