import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import D4yTable from '@/components/d4y-table/d4y-table.vue';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import searchData, { SearchData } from '@/shared/model/smallPayloadModels/searchData';
import { ROUTES } from '@/router/routesEnum';
import zrStat, { StatusIndices as ZrStatus, zrStatusesList } from '@/shared/model/smallPayloadModels/zrStatus';
import DateField from '@/components/_common/date-field/DateField.vue';
import { CONST } from '@/shared/utils/Constants';
import { UserData } from '@/shared/model/userData';
import zrDoc, { ZRDocument } from '@/shared/model/zrDocument';
import StatusDialog from './status-dialog/status-dialog.vue';
import { DefaultBackendHelper } from '@/shared/utils/backendHelper';
import GeneralUtils from '@/shared/utils/generalUtils';
import SupplierTooltip from './search-form/supplier-tooltip/supplier-tooltip.vue';
import { ZrNews } from '@/shared/model/zrNews';

const logger = new Logger('login');
const authModule = namespace('auth');
const userDataModule = namespace('userData');
const zrNewsModule = namespace('zrNews');

@Component({
  components: { D4yTable },
})
export default class ZRNewsList extends Vue {
  @authModule.Action('canSeeAll')
  private actionCanSeeAll!: any;
  @authModule.Getter('isUserInAzureAdminGroup')
  private isUserInAzureAdminGroupGetter!: any;
  @authModule.Getter('isUserSuperAdmin')
  private isUserSuperAdmin!: any;
  @authModule.Getter('isUserGsUser')
  private isUserGsUser!: any;
  @authModule.Getter('isUserOnlyGs')
  private isUserOnlyGs!: any;
  @authModule.Getter('zrNummer')
  private zrNummer!: any;
  @authModule.Getter('contactType')
  private contactType!: any;
  @authModule.Getter('zrNummerWithRoles')
  private zrNummerWithRoles!: any;
  @authModule.Getter('isUserSupport')
  private isUserSupport!: any;
  @authModule.Getter('isInitialRedirect')
  private isInitialRedirectGetter!: any;
  @authModule.Getter('supplierTooltipInfo')
  private supplierTooltipInfo!: any;

  @authModule.Getter('isUserHasRightsForDocuments')
  private isUserHasRightsForDocuments!: any;

  @zrNewsModule.Action('getZrNewsList')
  private actionGetZrNewsList!: any;
  @zrNewsModule.Getter('getZrNews')
  private getZrNews!: any;
  @zrNewsModule.Getter('getZrNewsIsLoading')
  private zrNewsIsLoading!: boolean;
  @zrNewsModule.Getter('getZrNewsSearchParams')
  private zrNewsSearchParams!: any;
  @zrNewsModule.Action('deleteZrNews')
  private actionDeleteZrNews!: any;

  @zrNewsModule.Action('upsertZrNewsReadInfo')
  private actionUpsertZrNewsReadInfo!: any;
  @zrNewsModule.Action('updateUnreadNews')
  private actionUpdateUnreadNews!: any;


  private searchPayload: any = {};
  @Watch('getSearchData', { deep: true })
  public async onOptionsFilterChanged(newVal: SearchData, oldVal: SearchData) {
    const areObjectsEqual = GeneralUtils.areObjectsEqual(newVal, oldVal, ['isUserInAzureAdminGroup', 'zrNummer']); // decided to ignore 2 props than include logic for them in `search-form` component

    console.log('areObjectsEqual :>> ', areObjectsEqual);
    logger.debug(`----filter:${oldVal}->${newVal}`);
    logger.log('newVal :>> ', newVal);
    logger.log('oldVal :>> ', oldVal);

    if (areObjectsEqual || this.isQueryBeforeCreatedDataLoad) {
      return;
    }

    // ignore request to backend until rights checked and tabled showed
    // showed use after `localStorage.setItem('searchData'` to work properly
    if (!this.isUserLoggedInAndHasRights) return;

    this.zrNewsSearchParams.dataOption.page = 1; // (GSP-082) go to 1st page, when change filter
    await this.getNewsData();
  }

  getZrNummerDependOnRole() {
    let result = this.isUserOnlyGs ? this.zrNummerWithRoles.zrdocumentZrNummer + '' || '-' : this.zrNummer + '';
    return result;
  }
  get isUserLoggedInAndHasRights() {
    return (
      (this.isUserInAzureAdminGroupGetter || (this.isUserGsUser && !!this.zrNummer)) && this.isUserHasRightsForDocuments
    );
  }

  // avoid extra query from watcher on initial page load
  get newsLoaded() {
    return this.zrNewsIsLoading !== undefined;
  }
  get isQueryBeforeCreatedDataLoad() {
    return this.zrNewsIsLoading === undefined;
  }

  get tableText() {
    return `${this.getZrNews.total} Mitteilungen`;
  }

  get initialRedirect() {
    return this.isInitialRedirectGetter;
  }

  get searchMenuObj() {
    return {
      // facetSearch: this.facetSearch,
       menuDisabled: true,
      // menuItems: this.menuItems,
      // hasResults: this.hasResults,
      // chipData: this.chipData,
    };
  }

  async created() {
    await this.actionCanSeeAll().catch((err: any) => {
      logger.error(err);
    });

    if (this.initialRedirect) return;
    if (this.isUserLoggedInAndHasRights) {
      await this.getNewsData();
    }
  }

  private isMounted = false; // avoid initial extra request in `selectionUpdateOptions`
  async mounted() {
    this.isMounted = true;
  }

  private async getNewsData() {
    this.searchPayload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    if (this.isUserGsUser) {
      this.searchPayload.zrNummer = this.getZrNummerDependOnRole();
    }

    const payload = { searchParams: this.zrNewsSearchParams, searchData: this.searchPayload, contactType: this.contactType }; // sent search request from localStorage on reload
    await this.actionGetZrNewsList(payload)
      .then((result: any) => {
        logger.log('result :>> ', result);
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  get items() {
    return this.getZrNews.items;
  }

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      align?: string;
      class?: string | string[];
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('picture_small'), value: 'bild_small' },
      { text: this.$t('title_column'), value: 'title' },
      { text: this.$t('text_column'), value: 'text' },
      { text: this.$t('author'), value: 'created_by' }, //align: 'right'
      { text: this.$t('created_date'), value: 'created_on' },
      { text: this.$t('attachments'), value: 'number_of_attachments' },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
      },
    ];
    return headers;
  }

  private openZrNews(item: any) {
    logger.log('openZrNews item :>> ', item);

    this.$router.push({
      name: ROUTES.news,
      params: { zrNewsId: item.id },
    });
  }

  onNewsAdd(){
    this.$router.push({
      name: ROUTES.news,
      params: {
        zrNewsId: '0',
      },
    });
  }

  deleteNews(record: ZrNews) {
    this.$confirm
    .open(
      `${this.$t('delete_dialog_title')}`,
      `${this.$t('delete_dialog_text')}`,
      {
        cancelText: this.$t('delete_dialog_cancel'),
        okText: this.$t('delete_dialog_ok'),
      }
    )
    .then(async (response: any) => {
      if (response) {
        await this.actionDeleteZrNews(record.id).then(() => {
          console.log('new Date() :>> ', new Date(record.valid_from));
          // if Correct Dates, then reduce news notification count
          if (new Date() > new Date(record.valid_from) && (!record.valit_until  || new Date() <= new Date(record.valit_until))) {
            this.actionUpdateUnreadNews(-1);
          }

          this.getNewsData();
        }).catch((err:any) => logger.error(err));
      }
    });
  }

  //#region GsUser zrNewsActions
  changeReadStatus(item:ZrNews, readStatus: boolean){
    console.log('item :>> ', item);
    let payload: any = {
      zr_news_id: item.id,
      is_read: readStatus,
      user_id: "",
      UpdateRead: true
    }
    if (item.news_read_info.length ==1) {
      payload.id = item.news_read_info[0].id;
    }

    this.actionUpsertZrNewsReadInfo(payload);
    logger.log('changeReadStatus', item);
  }
 

  markAsFavorite(item:any, value: any){
    logger.log('markAsFavorite value', value);
    let payload:any = {
      zr_news_id: item.id,
      is_favorite: value,
      user_id: "",
      UpdateFav: true
    }
    if (item.news_read_info.length ==1) {
      payload.id = item.news_read_info[0].id;
    }

    this.actionUpsertZrNewsReadInfo(payload);
  }
  //#endregion

  private async selectionUpdateOptions(newVal: any, action: any) {
    if (this.isMounted && this.zrNewsIsLoading !== undefined) {
      this.zrNewsSearchParams.dataOption = newVal;
      this.zrNewsSearchParams.filter = newVal.filter;
      this.getZRDocumentsWithParams();
    }
  }
  private async getZRDocumentsWithParams() {
    this.searchPayload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    if (this.isUserGsUser) {
      this.searchPayload.zrNummer = this.getZrNummerDependOnRole();
    }

    await this.actionGetZrNewsList({
      searchParams: this.zrNewsSearchParams,
      searchData: this.searchPayload,
      contactType: this.contactType
    });
  }

}
